import React, { useState } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import './Bookings.css'; // Assuming Bookings.css contains your desired styles

const CreateBooking = () => {
    const navigate = useNavigate();
    const [bookingData, setBookingData] = useState({
        name: '',
        date_for_booking: '',
        time_for_booking: '',
        amount_of_people: '',
        telephone_number: '',
        WANumber: '',
        Language: '',
        email_address: '',
        comments: '',
        status: 'available',
    });

    const handleChange = (e) => {
        setBookingData({ ...bookingData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newBooking = { ...bookingData, uniqueID: uuidv4() };
        try {
            await axios.post('https://smartspot.smart-serve.net/api/bookings', newBooking);
            alert('Booking created successfully');
            navigate('/bookings');
        } catch (error) {
            console.error('Failed to create booking:', error);
            alert('Failed to create booking');
        }
    };

    return (
        <div className="booking-form-container">
            <img src="/smartspot.png" alt="SmartSpot Logo" className="logo" />
            <form onSubmit={handleSubmit} className="booking-form">
                <div className="form-group">
                    <label>Name: <input type="text" name="name" value={bookingData.name} onChange={handleChange} required /></label>
                </div>
                <div className="form-group">
                    <label>Date for Booking: <input type="date" name="date_for_booking" value={bookingData.date_for_booking} onChange={handleChange} required /></label>
                </div>
                <div className="form-group">
                    <label>Time for Booking: <input type="time" name="time_for_booking" value={bookingData.time_for_booking} onChange={handleChange} required /></label>
                </div>
                <div className="form-group">
                    <label>Amount of People: <input type="number" name="amount_of_people" value={bookingData.amount_of_people} onChange={handleChange} required /></label>
                </div>
                <div className="form-group">
                    <label>Telephone Number: <input type="text" name="telephone_number" value={bookingData.telephone_number} onChange={handleChange} required /></label>
                </div>
                <div className="form-group">
                    <label>WA Number: <input type="text" name="WANumber" value={bookingData.WANumber} onChange={handleChange} /></label>
                </div>
                <div className="form-group">
                    <label>Language: <input type="text" name="Language" value={bookingData.Language} onChange={handleChange} /></label>
                </div>
                <div className="form-group">
                    <label>Email Address: <input type="email" name="email_address" value={bookingData.email_address} onChange={handleChange} /></label>
                </div>
                <div className="form-group">
                    <label>Comments: <textarea name="comments" value={bookingData.comments} onChange={handleChange}></textarea></label>
                </div>
                <div className="form-group">
                    <label>Status:
                        <select name="status" value={bookingData.status} onChange={handleChange}>
                            <option value="available">Available</option>
                            <option value="booked">Booked</option>
                            <option value="blocked">Blocked</option>
                        </select>
                    </label>
                </div>
                <button type="submit" className="btn-submit">Submit Booking</button>
            </form>
        </div>
    );
};

export default CreateBooking;
