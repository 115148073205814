import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Bookings.css'; // Make sure the path is correct

const Bookings = () => {
    const [bookings, setBookings] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchBookings();
    }, []);

    const fetchBookings = async () => {
        try {
            const response = await axios.get('https://smartspot.smart-serve.net/api/bookings');
            setBookings(response.data);
        } catch (error) {
            console.error('Failed to fetch bookings:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`https://smartspot.smart-serve.net/api/bookings/${id}`);
            fetchBookings(); // Refresh the bookings list
        } catch (error) {
            console.error('Failed to delete booking:', error);
        }
    };

    return (
        <div className="container">
            <img src={`${process.env.PUBLIC_URL}/smartspot.png`} alt="SmartSpot Logo" className="logo" />
            <div className="main-area">
                <h2></h2>
                <button onClick={() => navigate('/bookings/new')} className="btn btn-info">Create New Booking</button>
                <div className="table-responsive"></div>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Date of Booking</th>
                            <th>Time of Booking</th>
                            <th>Amount of People</th>
                            <th>Telephone Number</th>
                            <th>Language</th>
                            <th>Email Address</th>
                            <th>Comments</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bookings.map(booking => (
                            <tr key={booking.id}>
                                <td>{booking.date_for_booking}</td>
                                <td>{booking.time_for_booking}</td>
                                <td>{booking.amount_of_people}</td>
                                <td>{booking.telephone_number}</td>
                                <td>{booking.Language}</td>
                                <td>{booking.email_address}</td>
                                <td>{booking.comments}</td>
                                <td>{booking.status}</td>
                                <td>
                                    <button onClick={() => navigate(`/bookings/edit/${booking.id}`)} className="btn btn-primary">Edit</button>
                                    <button onClick={() => handleDelete(booking.id)} className="btn btn-danger">Delete</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <footer className="footer">
                Smartserve 2024
            </footer>
        </div>
    );
};

export default Bookings;
