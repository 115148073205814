// src/components/Login.js
import React from 'react';

function Login() {
  return (
    <div>
      <h2>Login Page</h2>
      {/* Login form will go here */}
    </div>
  );
}

export default Login;
