// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Login from './components/Login';
import Bookings from './components/Bookings';
import CreateBooking from './components/CreateBooking';
import EditBooking from './components/EditBooking'; // This is the new import
import 'bootstrap/dist/css/bootstrap.min.css';
import Admin from './components/Admin';
import SetupPage from './components/SetupPage';




function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/bookings" element={<Bookings />} />
        <Route path="/bookings/new" element={<CreateBooking />} />
        <Route path="/bookings/edit/:id" element={<EditBooking />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/setup" element={<SetupPage />} />
      </Routes>
    </Router>
  );
}

export default App;
