import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

function Home() {
  const navigate = useNavigate(); // Initialize useNavigate hook

  return (
    <div>
      <h1>Home Page</h1>
      <button onClick={() => navigate('/bookings')}>Go to Bookings</button> {/* Button to navigate to bookings */}
      <button onClick={() => navigate('/admin')}>Go to Admin</button> {/* Button to navigate to Admin */}
      <button onClick={() => navigate('/setup')}>Go to Bot Setup</button> {/* Button to navigate to SetupS */}
    </div>
  );
}

export default Home;
