import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Bookings.css'; // Assuming Bookings.css contains your desired styles

const Admin = () => {
    const navigate = useNavigate();
    const [adminConfig, setAdminConfig] = useState({
        wabaid: '',
        waphone: '',
        token: '',
        busname: '',
    });
    const [showToken, setShowToken] = useState(false); // State to toggle token visibility

    useEffect(() => {
        // Fetch the existing admin config
        const fetchAdminConfig = async () => {
            try {
                const response = await axios.get('https://smartspot.smart-serve.net/api/admin/config');
                setAdminConfig(response.data);
            } catch (error) {
                console.error('Failed to fetch admin config:', error);
            }
        };
        fetchAdminConfig();
    }, []);

    const handleChange = (e) => {
        setAdminConfig({ ...adminConfig, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post('https://smartspot.smart-serve.net/api/admin/config', adminConfig);
            alert('Admin configuration updated successfully');
        } catch (error) {
            console.error('Failed to update admin configuration:', error);
            alert('Failed to update admin configuration');
        }
    };

    const toggleShowToken = () => {
        setShowToken(!showToken);
    };

    return (
        <div className="booking-form-container">
            <img src="/smartspot.png" alt="SmartSpot Logo" className="logo" />
            <form onSubmit={handleSubmit} className="booking-form">
                {/* Other form groups */}
                <div className="form-group">
                    <label>WhatsApp Business ID: <input type="text" name="wabaid" value={adminConfig.wabaid} onChange={handleChange} required /></label>
                </div>
                <div className="form-group">
                    <label>WhatsApp Phone Number: <input type="text" name="waphone" value={adminConfig.waphone} onChange={handleChange} /></label>
                </div>
                <div className="form-group">
                    <label>Secret Token: 
                        <input 
                            type={showToken ? "text" : "password"} 
                            name="token" 
                            value={adminConfig.token} 
                            onChange={handleChange} 
                        />
                    </label>
                    <button type="button" onClick={toggleShowToken} className="btn-toggle">
                        {showToken ? "Hide" : "Show Token"}
                    </button>
                </div>
                <div className="form-group">
                    <label>Business Name: <input type="text" name="busname" value={adminConfig.busname} onChange={handleChange} /></label>
                </div>
                <button type="submit" className="btn-submit">Update Configuration</button>
            </form>
        </div>
    );
};

export default Admin;
