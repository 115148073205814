import React, { useState } from 'react';
import axios from 'axios';
import './Bookings.css'; // Ensure this CSS file is correctly referenced

const SetupPage = () => {
    const [selectedImage, setSelectedImage] = useState('');
    const [language, setLanguage] = useState('English');
    const [menus, setMenus] = useState({
        item1: { en: '', fr: '' },
        item2: { en: '', fr: '' },
        item3: { en: '', fr: '' },
        item4: { en: '', fr: '' },
        item5: { en: '', fr: '' },
    });

    // Handles image file selection and sets the file URL for preview
    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedImage(URL.createObjectURL(file));

            // Prepare the file to be sent to the backend
            const formData = new FormData();
            formData.append('image', file);

            try {
                // Assuming '/api/upload' is your backend endpoint for image uploads
                const response = await axios.post('http://localhost:3001/api/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                // Update the selectedImage state to the path returned from the backend
                setSelectedImage(response.data.imagePath);
            } catch (error) {
                console.error('Error uploading image:', error);
            }
        }
    };

    const handleSaveSetup = async () => {
        // Logic to send the setup data to the backend
        const setupData = { language, menus, imagePath: selectedImage };
        try {
            await axios.post('http://localhost:3001/api/setup', setupData);
            alert('Setup saved successfully!');
        } catch (error) {
            console.error('Error saving setup:', error);
            alert('Failed to save setup.');
        }
    };

    // Render the setup page form
    return (
        <div className="booking-form-container">
            <img src="/smartspot.png" alt="SmartSpot Logo" className="logo" />
            <h2>Setup Page</h2>

            <div className="form-group">
                <label>Image Preview and Upload:</label>
                <input type="file" onChange={handleImageChange} />
                {selectedImage && <img src={selectedImage} alt="Preview" className="image-preview" />}
            </div>

            <div className="form-group">
                <label>Language Selector:</label>
                <select value={language} onChange={(e) => setLanguage(e.target.value)}>
                    <option value="English">English</option>
                    <option value="French">French</option>
                </select>
            </div>

            {/* Render input fields for menu items here, similar to the existing structure */}
            {/* Example for item 1: */}
            <div className="form-group">
                <input type="text" placeholder="Menu 1 (English)" value={menus.item1.en} onChange={(e) => setMenus({ ...menus, item1: { ...menus.item1, en: e.target.value }})} />
                <input type="text" placeholder="Menu 1 (French)" value={menus.item1.fr} onChange={(e) => setMenus({ ...menus, item1: { ...menus.item1, fr: e.target.value }})} />
            </div>
            {/* Repeat for other menu items */}

            <button onClick={handleSaveSetup} className="btn-submit">Save Setup</button>
        </div>
    );
};

export default SetupPage;
